import { AnalyticEventProperties, Maybe, SearchEventMetadata } from "@/types"
import { SearchQuery, SearchResult } from "../types"
import { getMetadataCache, organicQueriesCache } from "./cache"
import { getEventProperties, getResultId, checkSearchMetadata } from "./helpers"
import { SearchAnalyticsOptions, SearchTrackOptions } from "./types"
import { reportAnalytics } from "./api"

/**
 * Record search event, should be send on any search
 */
export function recordSearch(
  type: SearchTrackOptions,
  query: SearchQuery,
  response: SearchResult,
  options?: SearchAnalyticsOptions
) {
  if (!type || !["serp", "autocomplete", "category"].includes(type)) {
    throw new Error(`Invalid search track option: ${type}`)
  }

  const { isKeyword = false } = options || {}

  const properties = getEventProperties(response.abTests ?? [])

  if (response && response.products && query) {
    const productIds = (response.products.hits || []).map(hit => hit.productId).filter(Boolean)
    const from = response.products.from ? response.products.from : 0
    const page = response.products.size ? from / response.products.size + 1 : 0

    if (type === "category") {
      const metadataCache = getMetadataCache(type)
      metadataCache.reset()
      const categoryPath = response.products.categoryPath || query.products?.categoryPath
      const categoryId = response.products.categoryId || query.products?.categoryId
      const metadata = categoryPath || categoryId ? { category: categoryPath!, categoryId } : undefined

      if (metadata) {
        metadataCache.set(metadata)
        void reportAnalytics("category", "impression", { productIds, metadata, page, properties })
      }

      return
    }

    const metadataCache = getMetadataCache(type)
    metadataCache.reset()
    const resultId = getResultId(type, query.query || "")
    const previousQuery = type === "serp" ? organicQueriesCache.get().slice(-2, -1)[0] : ""
    const isRefined = type === "serp" && !!previousQuery && previousQuery !== query

    const metadata: SearchEventMetadata = {
      query: query.query || "",
      resultId,
      isAutoCorrect: !!response.products.fuzzy,
      isAutoComplete: type === "autocomplete",
      isKeyword,
      isSorted: !!(query.products && query.products.sort),
      isOrganic: type === "serp" && organicQueriesCache.get().includes(query.query || ""),
      isRefined,
      ...(isRefined && { refinedQuery: previousQuery || "" }),
      hasResults:
        !!response?.products?.total ||
        !!response.redirect ||
        (Array.isArray(query?.products?.filter) ? !!query.products!.filter.length : !!query?.products?.filter)
    }

    metadataCache.set(metadata)

    void reportSearchImpression(productIds, metadata, page, properties)
  }
}

export async function reportSearchImpression(
  productIds: string[] = [],
  metadata: SearchEventMetadata,
  page: number = 1,
  properties: Maybe<AnalyticEventProperties>
) {
  await reportAnalytics("search", "impression", {
    productIds,
    metadata: checkSearchMetadata("impression", metadata),
    page,
    properties
  })
}

/**
 * Record search submit event (e.g. search form submit). Required to track organic searches.
 */
export function recordSearchSubmit(query: string) {
  if (query) {
    organicQueriesCache.push(query)
  }
}
